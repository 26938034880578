import { AppConfiguration } from "../../configuration";
import { portalServicesContactEnvironments } from "./contact-environments-portal-services";

export const GENERAL_ENV_PORTAL_SERVICES: AppConfiguration = {
  googleTagManagerContainerId: "GTM-NL396FD",
  contactEnvironments: portalServicesContactEnvironments,
  backUrl: "https://www.portal-services.ch",
  privacyUrl: {
    "de": "https://www.bkw.ch/de/datenschutzerklaerung",
    "fr": "https://www.bkw.ch/fr/declaration-de-protection-des-donnees",
    "it": "https://www.bkw.ch/it/dichiarazione-sulla-protezione-dei-dati",
    "en": "https://www.bkw.ch/en/privacy-policy",
  },
  noFeedback: true,
  companyDisplayName: "Portal Services",
  notisOrganisationName: "portal-services",
  hCaptchaSiteKey: "898c4806-787b-4dad-9efd-d974de630529",
};
