import { Environment } from "../../../contact/models/environment.interface";

export const portalServicesContactEnvironments: Environment[] = [
  {
    // environment for Portal-Services always with prefix "PS_"
    title: "PS_CC_MYBKW",
    reasons: [
      {
        title: "LOGIN",
        phoneNumber: undefined,
      },
    ],
  },
  {
    // environment for Portal-Services always with prefix "PS_"
    title: "PS_CC_BKW_BUSINESS",
    reasons: [
      {
        title: "LOGIN",
        phoneNumber: undefined,
      },
    ],
  },
  {
    // environment for Portal-Services always with prefix "PS_"
    title: "PS_CC_HINNI",
    reasons: [
      {
        title: "LOGIN",
        phoneNumber: undefined,
      },
    ],
  },
  {
    // environment for Portal-Services always with prefix "PS_"
    title: "PS_CC_TID",
    reasons: [
      {
        title: "LOGIN",
        phoneNumber: undefined,
      },
    ],
  },
  {
    // environment for Portal-Services always with prefix "PS_"
    title: "PS_CC_HUBO",
    reasons: [
      {
        title: "LOGIN",
        phoneNumber: undefined,
      },
    ],
  },
  {
    // environment for Portal-Services always with prefix "PS_"
    title: "PS_HOME_ENERGY",
    reasons: [
      {
        title: "LOGIN",
        phoneNumber: undefined,
      },
    ],
  },
  {
    // environment for Portal-Services always with prefix "PS_"
    title: "PS_CC_OTHER",
    reasons: [
      {
        title: "LOGIN",
        phoneNumber: undefined,
      },
    ],
  },
];
