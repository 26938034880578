export const FR_TRANSLATIONS = {
  "CONTACT": {
    "TITLE": "Formulaire de contact",
    "ENVIRONMENT": {
      "ACCOUNT": {
        "TITLE": "Espace clients",
        "REASON": {
          "GENERAL": "Demande d’ordre général",
          "LOGIN": "Connexion/Inscription",
          "ACCOUNT": "Mon compte",
          "BUSINESS": "Services pour les clients commerciaux & partenaires",
        },
      },
      "CUSTOMER_SERVICE": { 
        "TITLE": "Espace clients",
        "REASON": {
          "GENERAL": "Demande d’ordre général",
          "LOGIN": "Connexion/Inscription",
          "ACCOUNT": "Mon compte",
        },
      },
      "MYAEK": {
        "TITLE": "myAEK pour particuliers et entreprises",
        "REASON": {
          "EFFICIENCY": "Efficacité électrique",
          "INFEED": "Reprise de courant",
          "GENERAL": "Tout sur myAEK",
          "INVOICE": "Tout sur la facture",
          "PRODUCTS_PRICES": "Produits/prix",
        },
      },
      "MYONYX": {
        "TITLE": "myonyx pour particuliers et entreprises",
        "REASON": {
          "EFFICIENCY": "Efficacité électrique",
          "INFEED": "Reprise de courant",
          "GENERAL": "Tout sur myonyx",
          "INVOICE": "Tout sur la facture",
          "PRODUCTS_PRICES": "Produits/prix",
        },
      },
      "EICHFRIST": {
        "TITLE": "Gestion des lots",
        "REASON": {
          "MALFUNCTION": "L'application ne fonctionne pas correctement",
          "INSTRUCTION": "J'ai besoin d'aide concernant l'utilisation",
          "WRONG_DATA": "Les données affichées ne sont pas correctes",
          "GENERAL": "Demande d’ordre général",
        },
      },
      "INSTALLER_SERVICES": {
        "TITLE": "Installateur Services",
        "REASON": {
          "DATE_INQUIRY_MONTAGE": "(fr) Terminanfrage Zähler-apparate Montage FR",
          "DATE_INQUIRY_GRID_CONNECTION": "Terminanfrage Netzanschluss FR",
          "FAULT_REPORT_TRE": "Störungsmeldungen TRE, Zähler FR",
          "ORDER_INSTRUMENT_TRANSFORMER": "Bestellung Messwandler (Wie, Wann...) FR",
          "TEMPORARY_GRID_CONNECTION_BZK": "Temporärer Netzanschluss BZK FR",
          "NETWORK_QUALITY": "Netzqualität FR",
          "PERIODICAL_ELECTRICAL_INSTALLATION_CONTROL": "Periodische Elektroinstallationskontrolle FR",
          "OTHER": "Andere Themene FR",
        },
      },
      "M2C_BILLS": {
        "TITLE": "Factures M2C",
        "REASON": {
          "MALFUNCTION": "L'application ne fonctionne pas correctement",
          "INSTRUCTION": "J'ai besoin d'aide concernant l'utilisation",
          "WRONG_DATA": "Les données affichées ne sont pas correctes",
          "GENERAL": "Demande d’ordre général",
        },
      },
      "MDL": {
        "TITLE": "Prestations de mesure",
        "REASON": {
          "MALFUNCTION": "L'application ne fonctionne pas correctement",
          "INSTRUCTION": "J'ai besoin d'aide concernant l'utilisation",
          "WRONG_DATA": "Les données affichées ne sont pas correctes",
          "GENERAL": "Demande d’ordre général",
        },
      },
      "MYBKW": {
        "TITLE": "myBKW pour particuliers et entreprises",
        "REASON": {
          "CUSTOMER_CENTER": "Tout sur l’espace clients en ligne myBKW",
          "GENERAL": "Demande d’ordre général",
          "PRODUCTS_PRICES": "Produits/prix",
          "INVOICE": "Tout sur la facture",
          "POWER_SUPPLY": "Reprise de courant",
          "ACCOUNT_MANAGEMENT": "Gestion du compte",
          "REGISTRATION": "Inscription",
          "POWER_EFFICIENCY": "Efficacité électrique",
        },
      },
      "SMC": {
        "TITLE": "Smart Meter Connect",
        "REASON": {
          "MALFUNCTION": "L'application ne fonctionne pas correctement",
          "INSTRUCTION": "J'ai besoin d'aide concernant l'utilisation",
          "GENERAL": "Demande d’ordre général",
        },
      },
      "SMSK": {
        "TITLE": "Smart Meter Starter Kit",
        "REASON": {
          "MALFUNCTION": "L'application ne fonctionne pas correctement",
          "INSTRUCTION": "J'ai besoin d'aide concernant l'utilisation",
          "WRONG_DATA": "Les données affichées ne sont pas correctes",
          "GENERAL": "Demande d’ordre général",
        },
      },
      "VISUAL_EDM": {
        "TITLE": "Visualisation EDM",
        "REASON": {
          "MALFUNCTION": "L'application ne fonctionne pas correctement",
          "INSTRUCTION": "J'ai besoin d'aide concernant l'utilisation",
          "WRONG_DATA": "Les données affichées ne sont pas correctes",
          "GENERAL": "Demande d’ordre général",
        },
      },
      "PLANTSERVICES": {
        "TITLE": "Indisponibilités des centrales électriques",
        "REASON": {
          "MALFUNCTION": "Les plages d'indisponibilité n'ont pas pu être enregistrées",
          "WRONG_DATA": "Métadonnées erronées",
          "PLANT_MISSING": "L'installation manque dans la sélection",
          "POWER_AMOUNT": "Puissance installée incorrecte",
          "IMPROVEMENT": "Proposition d'amélioration",
        },
      },
      "DAVE_VISUALIZATION": {
        "TITLE": "Visualisation énergie",
        "REASON": {
          "MISSING_METERPOINT": "Point(s) de mesure manquant(s)",
          "DATA_MISSING": "Les valeurs ne sont pas disponibles",
          "DATA_WRONG": "Données de base erronées",
          "ERROR": "Message d'erreur",
          "DATA_OUTDATED": "Les valeurs ne sont pas à jour",
          "ERROR_DATASHEET": "Le tableau des données de mesure n'est pas affiché correctement",
          "ERROR_DOWNLOAD": "Le téléchargement ne fonctionne pas",
          "ERROR_PDF": "Le PDF ne peut pas être généré",
          "OTHER": "Autre raison",
        },
      },
      "GENERAL": {
        "TITLE": "Services généraux BKW",
        "REASON": {
          "SHOP": "Panier / Shop",
          "HOME_ENERGY": "Home Energy",
          "OUTAGE": "Trouver coupures de courant",
        },
      },
      "ENERGIEBILANZ": {
        "TITLE": "Bilan d'énergie",
        "REASON": {
          "ORDER": "Commander l'accès",
          "MALFUNCTION": "L'application ne fonctionne pas correctement",
          "INSTRUCTION": "J'ai besoin d'aide concernant l'utilisation",
          "WRONG_DATA": "Les données affichées ne sont pas correctes",
          "GENERAL": "Demande d’ordre général",
        },
      },
      "GRIDCONNECTION": {
        "TITLE": "Raccordement au réseau",
        "REASON": {
          "GENERAL": "Demande d'ordre général",
          "MALFUNCTION": "L'application ne fonctionne pas correctement",
          "INSTRUCTION": "J'ai besoin d'aide concernant l'utilisation",
        },
      },
      "EVU_MGMTSERVICES": {
        "TITLE": "Services de gestion",
        "REASON": {
          "APPOINTMENT": "Conseil personnel",
        },
      },
      "M2C_SHOP": {
        "TITLE": "M2C Shop",
        "REASON": {
          "QUESTION": "Question au vendeur",
        },
      },
      "OSTRAL": {
        "TITLE": "Consultation d'Ostral",
        "REASON": {
          "GENERAL": "Demande d’ordre général",
        },
      },
      "MDV": {
        "TITLE": "Visualisation des données mesurées",
        "REASON": {
          "GENERAL": "Demande d’ordre général",
        },
      },
      "PS_CC_MYBKW": {
        "TITLE": "Espace clients myBKW",
        "REASON": {
          "LOGIN": "Login / Portal Services",
        },
      },
      "PS_CC_BKW_BUSINESS": {
        "TITLE": "Espace clients BKW Business",
        "REASON": {
          "LOGIN": "Login / Portal Services",
        },
      },
      "PS_CC_HINNI": {
        "TITLE": "Portail clients Hinni",
        "REASON": {
          "LOGIN": "Login / Portal Services",
        },
      },
      "PS_CC_TID": {
        "TITLE": "Espace clients TID",
        "REASON": {
          "LOGIN": "Login / Portal Services",
        },
      },
      "PS_CC_HUBO": {
        "TITLE": "Espace clients Hubo",
        "REASON": {
          "LOGIN": "Login / Portal Services",
        },
      },
      "PS_HOME_ENERGY": {
        "TITLE": "Home Energy App",
        "REASON": {
          "LOGIN": "Login / Portal Services",
        },
      },
      "PS_CC_OTHER": {
        "TITLE": "Autre espace clients",
        "REASON": {
          "LOGIN": "Login / Portal Services",
        },
      },
    },
    "REASON": "Motif de la prise de contact",
    "CUSTOMER_MESSAGE": "Votre message",
    "REASON_TITLE": "Votre demande",
    "APPOINTMENT": {
      "TITLE": "Saisissez la date souhaitée",
      "URGENT": {
        "CHECKBOX": "Traitement urgent",
        "HINT": "À sélectionner uniquement si le traitement doit avoir lieu dans un délai de 24 heures.",
      },
      "HINT": "Nous vous confirmerons définitivement votre demande de rendez-vous par e-mail. Si le rendez-vous ne peut pas avoir lieu, nous vous contacterons.",
      "DATUM": "Date",
      "TIME": "Heure",
      "CANCEL": "Annuler",
      "SUBMIT": "Accepter",
    },
    "PERSONAL_DATA": "Vos coordonnées",
    "SALUTATION": "Civilité",
    "SALUTATIONS": {
      "MALE": "Monsieur",
      "FEMALE": "Madame",
    },
    "FIRST_NAME": "Prénom",
    "LAST_NAME": "Nom",
    "STREET": "Rue",
    "HOUSE_NO": "N°",
    "ZIP": "NPA",
    "CITY": "Localité",
    "EMAIL": "E-mail",
    "PHONE": "Numéro de téléphone",
    "SAP_ISU_ACCOUNT_ID": "Numéro de client",
    "REQUIRED_FIELD": "Champ obligatoire",
    "INVALID_ZIP": "NPA non valide",
    "SUBMIT": "Envoyer",
    "CANCEL": "Annuler",
    "BACK": "Retour",
    "BACK_TO": "Retour à {{site}}",
    "PRIVACY": "J'approuve <a class=\"text-underline\" href=\"{{href}}\" target=\"_blank\">la déclaration de confidentialité</a> de {{company}}.",
    "SECONDARY_CONTENT": {
      "TITLE": "Nous nous tenons à votre disposition pour toute question.",
      "PHONE": "Téléphone",
    },
    "PARENT_TITLE": "Espace clients",
    "PARENT_URL": "https://account.bkw.ch",
    "PARENT_SERVICES": "Services",
    "SUCCESS": "La demande de contact a bien été envoyée.",
    "ERROR": "La demande de contact n’a pas pu être envoyée.",
    "SUCCESS_TITLE": "Merci pour votre demande de contact.",
    "SUCCESS_TEXT": "Nous traiterons votre demande au plus vite possible et serons ravis de pouvoir vous aider.",
  },
};
