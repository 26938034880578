import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BFC_TRANSLATIONS, BfcTranslationModule } from "@bfl/components/translation";
import { translations } from "./config/translations/translations";
import { BFC_CONFIGURATIONS, BfcConfigurationModule } from "@bfl/components/configuration";
import { BfcGoogleAnalyticsModule } from "@bfl/components/google-analytics";
import { configuration } from "./config/configuration";
import { AppRoutingModule } from "./app-routing.module";
import { CoreModule } from "./core/core.module";
import { FlexLayoutModule } from "@angular/flex-layout";
import {
  BFC_AUTHENTICATION_INTERCEPTOR_WHITELIST,
  BfcAuthenticationInterceptor,
  BfcAuthenticationModule,
} from "@bfl/components/authentication";
import { BfcLoadingModule } from "@bfl/components/loading";
import { BfcSinglePageLayoutModule } from "@bfl/components/single-page-layout";
import { BfcSinglePageLayoutLargeModule } from "@bfl/components/single-page-layout-large";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { authenticationInterceptorWhitelist } from "./config/authentication-interceptor-whitelist";

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    AppRoutingModule,
    BfcTranslationModule,
    BfcGoogleAnalyticsModule,
    BfcConfigurationModule,
    BfcAuthenticationModule,
    CoreModule,
    BfcLoadingModule,
    BfcSinglePageLayoutModule,
    BfcSinglePageLayoutLargeModule,
  ],
  exports: [
    BfcTranslationModule,
  ],
  declarations: [
    AppComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BfcAuthenticationInterceptor,
      multi: true,
    },
    {
      provide: BFC_AUTHENTICATION_INTERCEPTOR_WHITELIST,
      useValue: authenticationInterceptorWhitelist,
    },
    {
      provide: BFC_CONFIGURATIONS,
      useValue: configuration,
      multi: true,
    },
    {
      provide: BFC_TRANSLATIONS,
      useValue: translations,
      multi: true,
    },
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA, // because of bfe-b2b-navigation
  ],
  bootstrap: [
    AppComponent,
  ],
})
export class AppModule {
}
