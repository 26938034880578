import { Component, HostBinding, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
  selector: "app-component",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {

  @HostBinding("class")
  classes = "bfc-base";

  private readonly searchParam: string = "withSideNav=";

  private unsubscribe: Subject<void> = new Subject();

  @HostBinding("class.app-component-with-side-nav")
  withSideNav: boolean = false;

  constructor(private router: Router, private activatedRoute: ActivatedRoute){
  }

  ngOnInit() {

    this.activatedRoute.queryParams.pipe(
      takeUntil(this.unsubscribe),
    ).subscribe(params => {
      if (params.withSideNav){
        this.withSideNav = JSON.parse(params.withSideNav);
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
  
  public routeRequest(e): void {
    this.router.navigate([e.detail.path]);
  }
}
