import { Environment } from "../../../contact/models/environment.interface";

export const onePortalContactEnvironments: Environment[] = [
  {
    title: "ACCOUNT",
    reasons: [
      {
        title: "GENERAL",
        phoneNumber: undefined, //"0844 121 113"
      },
      {
        title: "LOGIN",
        phoneNumber: undefined, //"0844 121 113"
      },
      {
        title: "ACCOUNT",
        phoneNumber: undefined, //"0844 121 113"
      },
      {
        title: "BUSINESS",
        phoneNumber: "058 477 5070",
      },
    ],
  },
  {
    title: "EICHFRIST",
    reasons: [
      {
        title: "MALFUNCTION",
        phoneNumber: undefined,
      },
      {
        title: "INSTRUCTION",
        phoneNumber: undefined,
      },
      {
        title: "WRONG_DATA",
        phoneNumber: undefined,
      },
      {
        title: "GENERAL",
        phoneNumber: undefined,
      },
    ],
  },
  {
    title: "M2C_BILLS",
    reasons: [
      {
        title: "MALFUNCTION",
        phoneNumber: "058 477 23 23",
      },
      {
        title: "INSTRUCTION",
        phoneNumber: "058 477 23 23",
      },
      {
        title: "WRONG_DATA",
        phoneNumber: "058 477 23 23",
      },
      {
        title: "GENERAL",
        phoneNumber: "058 477 23 23",
      },
    ],
  },
  {
    title: "MDL",
    reasons: [
      {
        title: "MALFUNCTION",
        phoneNumber: "058 477 23 23",
      },
      {
        title: "INSTRUCTION",
        phoneNumber: "058 477 23 23",
      },
      {
        title: "WRONG_DATA",
        phoneNumber: "058 477 23 23",
      },
      {
        title: "GENERAL",
        phoneNumber: "058 477 23 23",
      },
    ],
  },
  {
    title: "MYBKW",
    reasons: [
      {
        title: "CUSTOMER_CENTER",
        phoneNumber: undefined,
      },
      {
        title: "GENERAL",
        phoneNumber: undefined,
      },
      {
        title: "PRODUCTS_PRICES",
        phoneNumber: undefined,
      },
      {
        title: "INVOICE",
        phoneNumber: undefined,
      },
      {
        title: "POWER_SUPPLY",
        phoneNumber: undefined,
      },
      {
        title: "ACCOUNT_MANAGEMENT",
        phoneNumber: undefined,
      },
      {
        title: "REGISTRATION",
        phoneNumber: undefined,
      },
      {
        title: "POWER_EFFICIENCY",
        phoneNumber: undefined,
      },
    ],
  },
  {
    title: "SMC",
    reasons: [
      {
        title: "MALFUNCTION",
        phoneNumber: "058 477 23 23",
      },
      {
        title: "INSTRUCTION",
        phoneNumber: "058 477 23 23",
      },
      {
        title: "GENERAL",
        phoneNumber: "058 477 23 23",
      },
    ],
  },
  {
    title: "SMSK",
    reasons: [
      {
        title: "MALFUNCTION",
        phoneNumber: "058 477 23 23",
      },
      {
        title: "INSTRUCTION",
        phoneNumber: "058 477 23 23",
      },
      {
        title: "WRONG_DATA",
        phoneNumber: "058 477 23 23",
      },
      {
        title: "GENERAL",
        phoneNumber: "058 477 23 23",
      },
    ],
  },
  {
    title: "VISUAL_EDM",
    reasons: [
      {
        title: "MALFUNCTION",
        phoneNumber: "058 477 23 23",
      },
      {
        title: "INSTRUCTION",
        phoneNumber: "058 477 23 23",
      },
      {
        title: "WRONG_DATA",
        phoneNumber: "058 477 23 23",
      },
      {
        title: "GENERAL",
        phoneNumber: "058 477 23 23",
      },
    ],
  },
  {
    title: "PLANTSERVICES",
    reasons: [
      {
        title: "MALFUNCTION",
        phoneNumber: "+41 58 477 62 66",
      },
      {
        title: "WRONG_DATA",
        phoneNumber: "+41 58 477 62 66",
      },
      {
        title: "PLANT_MISSING",
        phoneNumber: "+41 58 477 62 66",
      },
      {
        title: "POWER_AMOUNT",
        phoneNumber: "+41 58 477 62 66",
      },
    ],
  },
  {
    title: "DAVE_VISUALIZATION",
    reasons: [
      {
        title: "MISSING_METERPOINT",
        phoneNumber: "058 477 5070",
      },
      {
        title: "DATA_MISSING",
        phoneNumber: "058 477 5070",
      },
      {
        title: "DATA_WRONG",
        phoneNumber: "+41 58 477 43 40",
      },
      {
        title: "ERROR",
        phoneNumber: "058 477 5070",
      },
      {
        title: "DATA_OUTDATED",
        phoneNumber: "+41 58 477 43 40",
      },
      {
        title: "ERROR_DATASHEET",
        phoneNumber: "058 477 5070",
      },
      {
        title: "ERROR_DOWNLOAD",
        phoneNumber: "058 477 5070",
      },
      {
        title: "ERROR_PDF",
        phoneNumber: "058 477 5070",
      },
      {
        title: "OTHER",
        phoneNumber: "058 477 5070",
      },
    ],
  },
  {
    title: "ENERGIEBILANZ",
    reasons: [
      {
        title: "ORDER",
        phoneNumber: undefined,
      },
      {
        title: "MALFUNCTION",
        phoneNumber: undefined,
      },
      {
        title: "INSTRUCTION",
        phoneNumber: undefined,
      },
      {
        title: "WRONG_DATA",
        phoneNumber: undefined,
      },
      {
        title: "GENERAL",
        phoneNumber: undefined,
      },
    ],
  },
  {
    title: "GRIDCONNECTION",
    reasons: [
      {
        title: "GENERAL",
        phoneNumber: undefined,
      },
      {
        title: "MALFUNCTION",
        phoneNumber: undefined,
      },
      {
        title: "INSTRUCTION",
        phoneNumber: undefined,
      },
    ],
  },
  {
    title: "EVU_MGMTSERVICES",
    reasons: [
      {
        title: "APPOINTMENT",
        phoneNumber: undefined,
      },
    ],
    onlyWhenUserHasAnyRoles: [ "ROLE_B2B_CRM_CONTACT_PERSON" ],
  },
  {
    title: "M2C_SHOP",
    reasons: [
      {
        title: "QUESTION",
        phoneNumber: undefined,
      },
    ],
    onlyWhenUserHasAnyRoles: [ "ROLE_M2C_SHOP" ],
  },
  {
    title: "OSTRAL",
    reasons: [
      {
        title: "GENERAL",
        phoneNumber: undefined,
      },
    ],
    onlyWhenUserHasAnyRoles: [ "ROLE_OSTRAL_REQUEST" ],
  },
  {
    title: "MDV",
    reasons: [
      {
        title: "GENERAL",
        phoneNumber: undefined,
      },
    ],
    onlyWhenUserHasAnyRoles: [ "ROLE_MDV" ],
  },
];
