import { Injectable } from "@angular/core";
import { BfcGoogleAnalyticsService } from "@bfl/components/google-analytics";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { BfcTranslationService } from "@bfl/components/translation";

@Injectable()
export class InitializerProvider {

  constructor(private bfcGoogleAnalyticsService: BfcGoogleAnalyticsService,
    private bfcConfigurationService: BfcConfigurationService,
    private bfcTranslationService: BfcTranslationService) {

  }

  public initialize() {
    this.bfcGoogleAnalyticsService
      .initGoogleTagManager(this.bfcConfigurationService.configuration.googleTagManagerContainerId);

    const globalNavElements = document.getElementsByTagName("bfe-global-navigation");
    if (globalNavElements.length === 1) {
      globalNavElements[0].setAttribute("service-items-modify", JSON.stringify([
        {
          key: "contact",
          title: this.bfcTranslationService.translate("CONTACT.TITLE"),
          url: "/contact",
        }]));

    } else {
      throw (new Error("too many or no global navigation element found"));
    }
  }
}
