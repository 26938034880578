export const DE_TRANSLATIONS = {
  "CONTACT": {
    "TITLE": "Kontaktformular",
    "ENVIRONMENT": {
      "ACCOUNT": {
        "TITLE": "Kundencenter",
        "REASON": {
          "GENERAL": "Allgemeine Anfrage",
          "LOGIN": "Login / Registration",
          "ACCOUNT": "Mein Konto",
          "BUSINESS": "Services für Geschäftskunden & Partner",
        },
      },
      "CUSTOMER_SERVICE": {
        "TITLE": "Kundencenter",
        "REASON": {
          "GENERAL": "Allgemeine Anfrage",
          "LOGIN": "Login / Registration",
          "ACCOUNT": "Mein Konto",
        },
      },
      "MYAEK": {
        "TITLE": "myAEK für Private & Gewerbe",
        "REASON": {
          "EFFICIENCY": "Stromeffizienz",
          "INFEED": "Stromeinspeisung",
          "GENERAL": "Rund um myAEK",
          "INVOICE": "Rund um die Stromrechnung",
          "PRODUCTS_PRICES": "Produkte / Preise",
        },
      },
      "MYONYX": {
        "TITLE": "myonyx für Private & Gewerbe",
        "REASON": {
          "EFFICIENCY": "Stromeffizienz",
          "INFEED": "Stromeinspeisung",
          "GENERAL": "Rund um myonyx",
          "INVOICE": "Rund um die Stromrechnung",
          "PRODUCTS_PRICES": "Produkte / Preise",
        },
      },
      "EICHFRIST": {
        "TITLE": "Losverwaltung",
        "REASON": {
          "MALFUNCTION": "Die Anwendung funktioniert nicht richtig",
          "INSTRUCTION": "Ich benötige Hilfe bei der Bedienung",
          "WRONG_DATA": "Die angezeigten Daten sind nicht korrekt",
          "GENERAL": "Allgemeine Frage",
        },
      },
      "INSTALLER_SERVICES": {
        "TITLE": "Installateur Services",
        "REASON": {
          "DATE_INQUIRY_MONTAGE": "Terminanfrage Zähler-apparate Montage",
          "DATE_INQUIRY_GRID_CONNECTION": "Terminanfrage Netzanschluss",
          "FAULT_REPORT_TRE": "Störungsmeldungen TRE, Zähler",
          "ORDER_INSTRUMENT_TRANSFORMER": "Bestellung Messwandler (Wie, Wann...)",
          "TEMPORARY_GRID_CONNECTION_BZK": "Temporärer Netzanschluss BZK",
          "NETWORK_QUALITY": "Netzqualität",
          "PERIODICAL_ELECTRICAL_INSTALLATION_CONTROL": "Periodische Elektroinstallationskontrolle",
          "OTHER": "Andere Themen",
        },
      },
      "M2C_BILLS": {
        "TITLE": "Meter2Cash Rechnungen",
        "REASON": {
          "MALFUNCTION": "Die Anwendung funktioniert nicht richtig",
          "INSTRUCTION": "Ich benötige Hilfe bei der Bedienung",
          "WRONG_DATA": "Die angezeigten Daten sind nicht korrekt",
          "GENERAL": "Allgemeine Frage",
        },
      },
      "MDL": {
        "TITLE": "Messdienstleistungen",
        "REASON": {
          "MALFUNCTION": "Die Anwendung funktioniert nicht richtig",
          "INSTRUCTION": "Ich benötige Hilfe bei der Bedienung",
          "WRONG_DATA": "Die angezeigten Daten sind nicht korrekt",
          "GENERAL": "Allgemeine Frage",
        },
      },
      "MYBKW": {
        "TITLE": "myBKW für Private & Gewerbe",
        "REASON": {
          "CUSTOMER_CENTER": "Rund um das Online-Kundencenter myBKW",
          "GENERAL": "Allgemeine Anfrage",
          "PRODUCTS_PRICES": "Produkte/Preise",
          "INVOICE": "Rund um die Rechnung",
          "POWER_SUPPLY": "Stromeinspeisung",
          "ACCOUNT_MANAGEMENT": "Kontoverwaltung",
          "REGISTRATION": "Registration",
          "POWER_EFFICIENCY": "Stromeffizienz",
        },
      },
      "SMC": {
        "TITLE": "Smart Meter Connect",
        "REASON": {
          "MALFUNCTION": "Die Anwendung funktioniert nicht richtig",
          "INSTRUCTION": "Ich benötige Hilfe bei der Bedienung",
          "GENERAL": "Allgemeine Frage",
        },
      },
      "SMSK": {
        "TITLE": "Smart Meter Starter Kit",
        "REASON": {
          "MALFUNCTION": "Die Anwendung funktioniert nicht richtig",
          "INSTRUCTION": "Ich benötige Hilfe bei der Bedienung",
          "WRONG_DATA": "Die angezeigten Daten sind nicht korrekt",
          "GENERAL": "Allgemeine Frage",
        },
      },
      "VISUAL_EDM": {
        "TITLE": "Visualisierung EDM",
        "REASON": {
          "MALFUNCTION": "Die Anwendung funktioniert nicht richtig",
          "INSTRUCTION": "Ich benötige Hilfe bei der Bedienung",
          "WRONG_DATA": "Die angezeigten Daten sind nicht korrekt",
          "GENERAL": "Allgemeine Frage",
        },
      },
      "PLANTSERVICES": {
        "TITLE": "Nichtverfügbarkeiten von Kraftwerken",
        "REASON": {
          "MALFUNCTION": "Nichtverfügbarkeit kann nicht erfasst werden",
          "WRONG_DATA": "Stammdaten fehlerhaft",
          "PLANT_MISSING": "Anlage fehlt in der Auswahl",
          "POWER_AMOUNT": "Installierte Leistung nicht korrekt",
          "IMPROVEMENT": "Verbesserungsvorschlag",
        },
      },
      "DAVE_VISUALIZATION": {
        "TITLE": "Visualisierung Energie",
        "REASON": {
          "MISSING_METERPOINT": "Messpunkt fehlt in der Auswahl",
          "DATA_MISSING": "Werte sind nicht vorhanden",
          "DATA_WRONG": "Stammdaten fehlerhaft ",
          "ERROR": "Fehlermeldung ",
          "DATA_OUTDATED": "Werte sind nicht aktuell ",
          "ERROR_DATASHEET": "Datenblatt wird nicht korrekt angezeigt",
          "ERROR_DOWNLOAD": "Download funktioniert nicht",
          "ERROR_PDF": "Pdf kann nicht erstellt werden",
          "OTHER": "Anderer Grund",
        },
      },
      "GENERAL": {
        "TITLE": "Allgemeine BKW Services",
        "REASON": {
          "SHOP": "Warenkorb / Shop",
          "HOME_ENERGY": "Home Energy",
          "OUTAGE": "Stromausfall finden",
        },
      },
      "ENERGIEBILANZ": {
        "TITLE": "Energiebilanz",
        "REASON": {
          "ORDER": "Zugang bestellen",
          "MALFUNCTION": "Die Anwendung funktioniert nicht richtig",
          "INSTRUCTION": "Ich benötige Hilfe bei der Bedienung",
          "WRONG_DATA": "Die angezeigten Daten sind nicht korrekt",
          "GENERAL": "Allgemeine Frage",
        },
      },
      "GRIDCONNECTION": {
        "TITLE": "Netzanschluss",
        "REASON": {
          "GENERAL": "Allgemeine Anfrage",
          "MALFUNCTION": "Die Anwendung funktioniert nicht richtig",
          "INSTRUCTION": "Ich benötige Hilfe bei der Bedienung",
        },
      },
      "EVU_MGMTSERVICES": {
        "TITLE": "Management Services",
        "REASON": {
          "APPOINTMENT": "Beratungstermin",
        },
      },
      "M2C_SHOP": {
        "TITLE": "M2C Shop",
        "REASON": {
          "QUESTION": "Frage an Geräteverkauf",
        },
      },
      "OSTRAL": {
        "TITLE": "Ostral Abfrage",
        "REASON": {
          "GENERAL": "Allgemeine Anfrage",
        },
      },
      "MDV": {
        "TITLE": "Messdaten Visualisierung",
        "REASON": {
          "GENERAL": "Allgemeine Anfrage",
        },
      },
      "PS_CC_MYBKW": {
        "TITLE": "BKW Kundencenter myBKW",
        "REASON": {
          "LOGIN": "Login / Portal Services",
        },
      },
      "PS_CC_BKW_BUSINESS": {
        "TITLE": "BKW Kundencenter BKW Business",
        "REASON": {
          "LOGIN": "Login / Portal Services",
        },
      },
      "PS_CC_HINNI": {
        "TITLE": "Hinni Kundenportal",
        "REASON": {
          "LOGIN": "Login / Portal Services",
        },
      },
      "PS_CC_TID": {
        "TITLE": "TID Kundencenter",
        "REASON": {
          "LOGIN": "Login / Portal Services",
        },
      },
      "PS_CC_HUBO": {
        "TITLE": "Hubo Kundencenter",
        "REASON": {
          "LOGIN": "Login / Portal Services",
        },
      },
      "PS_HOME_ENERGY": {
        "TITLE": "Home Energy App",
        "REASON": {
          "LOGIN": "Login / Portal Services",
        },
      },
      "PS_CC_OTHER": {
        "TITLE": "Anderes Kundencenter",
        "REASON": {
          "LOGIN": "Login / Portal Services",
        },
      },
    },
    "REASON": "Grund des Kontakts",
    "CUSTOMER_MESSAGE": "Ihre Mitteilung",
    "REASON_TITLE": "Ihr Anliegen",
    "APPOINTMENT": {
      "TITLE": "Geben Sie Ihren Wunschtermin ein",
      "URGENT": {
        "CHECKBOX": "Dringende Bearbeitung",
        "HINT": "Nur anwählen, wenn die Bearbeitung innerhalb von 24 Stunden erfolgen soll.",
      },
      "HINT": "Sie erhalten eine definitive Bestätigung der Terminanfrage per E-Mail. Falls der Termin nicht wahrgenommen werden kann, kontaktieren wir Sie.",
      "DATUM": "Datum",
      "TIME": "Uhrzeit",
      "CANCEL": "Abbrechen",
      "SUBMIT": "Übernehmen",
    },
    "PERSONAL_DATA": "Ihre Kontaktdaten",
    "SALUTATION": "Anrede",
    "SALUTATIONS": {
      "MALE": "Herr",
      "FEMALE": "Frau",
    },
    "FIRST_NAME": "Vorname",
    "LAST_NAME": "Nachname",
    "STREET": "Strasse",
    "HOUSE_NO": "Nr.",
    "ZIP": "PLZ",
    "CITY": "Ort",
    "EMAIL": "E-Mail",
    "PHONE": "Telefonnummer",
    "SAP_ISU_ACCOUNT_ID": "Kundennummer",
    "REQUIRED_FIELD": "Dies ist ein Pflichtfeld",
    "INVALID_ZIP": "Ungültige Postleitzahl",
    "SUBMIT": "Absenden",
    "CANCEL": "Abbrechen",
    "BACK": "Zurück",
    "BACK_TO": "Zurück zu {{site}}",
    "PRIVACY": "Ich erkläre mich einverstanden mit der <a class=\"text-underline\" href=\"{{href}}\" target=\"_blank\">Datenschutzerklärung</a> der {{company}}.",
    "SECONDARY_CONTENT": {
      "TITLE": "Bei Fragen stehen wir Ihnen zur Seite!",
      "PHONE": "Telefon",
    },
    "PARENT_TITLE": "Kundencenter",
    "PARENT_URL": "https://account.bkw.ch",
    "PARENT_SERVICES": "Services",
    "SUCCESS": "Kontaktanfrage erfolgreich abgesendet",
    "ERROR": "Kontaktanfrage konnte nicht gesendet werden",
    "SUCCESS_TITLE": "Vielen Dank für Ihre Kontaktanfrage",
    "SUCCESS_TEXT": "Wir bearbeiten Ihre Anfrage schnellstmöglich und freuen uns, Sie unterstützen zu können.",
  },
};
