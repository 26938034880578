import { AppConfiguration } from "../../configuration";
import { onePortalContactEnvironments } from "./contact-environments-bkw";

export const GENERAL_ENV: AppConfiguration = {
  googleTagManagerContainerId: "GTM-NL396FD",
  contactEnvironments: onePortalContactEnvironments,
  backUrl: "https://www.bkw.ch",
  privacyUrl: {
    "de": "https://www.bkw.ch/de/datenschutzerklaerung",
    "fr": "https://www.bkw.ch/fr/declaration-de-protection-des-donnees",
    "it": "https://www.bkw.ch/it/dichiarazione-sulla-protezione-dei-dati",
    "en": "https://www.bkw.ch/en/privacy-policy",
  },
  companyDisplayName: "BKW",
  notisOrganisationName: "bkw",
  originTextFutureCrm: "Kundenportal",
  hCaptchaSiteKey: "898c4806-787b-4dad-9efd-d974de630529",
};
